import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import '@nosferatu500/react-sortable-tree/style.css'; // This only needs to be imported once in your app
import './i18n';
import App from './App';
import { ThemeContextProvider } from './contexts/themeContext';

window.progressbar = null;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Router>
		<ThemeContextProvider>
			<App />
		</ThemeContextProvider>
	</Router>
);
