import React, { lazy } from 'react';
const Login = lazy(() => import('../pages/CommonMenu/Login/login'));
const MicChartPage = lazy(() => import('../pages/rnslab/charts/micChart'));
const XyzChartPage = lazy(() => import('../pages/rnslab/charts/xyzChart'));
const XyzChart2Page = lazy(() => import('../pages/rnslab/charts/xyzChart2'));
const FFTChartPage = lazy(() => import('../pages/rnslab/charts/fftChart'));
const NrDashboardPage = lazy(() => import('../pages/rnslab/dashboard/NrDashboard'));
const NrCollectedDataList = lazy(() => import('../pages/rnslab/nrcollectedData/NrCollectedDataList'));
const NrSystemSetting = lazy(() => import('../pages/rnslab/nrSystemSetting'));
const NrDeviceList = lazy(() => import('../pages/rnslab/nrcollectedData/NrDeviceList'));
const Profile = lazy(() => import('../pages/rnslab/Profile'));
const NrCollectedDataList2 = lazy(() => import('../pages/rnslab/nrcollectedData/NrCollectedDataList2'));
const NrCollectedDataList3 = lazy(() => import('../pages/rnslab/nrcollectedData/NrCollectedDataList3'));

const contents = {
	common: {
		profile: {
			id: 'profile',
			text: '프로필',
			type: 'user',
			icon: 'Profile',
			path: '/profile',
			element: <Profile />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
	},
	withOutAsidePage: {
		login: {
			id: 'login',
			text: '로그인',
			type: 'withOutAsidePage',
			icon: 'Login',
			path: '/login',
			element: <Login />,
			exact: true,
			subMenu: null,
		},
		fft: {
			id: 'test',
			text: '테스트',
			type: 'withOutAsidePage',
			icon: 'Test',
			path: '/fft',
			element: <FFTChartPage />,
			exact: true,
			subMenu: null,
		},
		mic: {
			id: 'mic',
			text: '테스트',
			type: 'withOutAsidePage',
			icon: 'Mic',
			path: '/mic',
			element: <MicChartPage />,
			exact: true,
			subMenu: null,
		},
		xyz: {
			id: 'xyz',
			text: '테스트',
			type: 'withOutAsidePage',
			icon: 'Xyz',
			path: '/xyz',
			element: <XyzChartPage />,
			exact: true,
			subMenu: null,
		},
		xyz2: {
			id: 'xyz2',
			text: '테스트',
			type: 'withOutAsidePage',
			icon: 'Xyz2',
			path: '/xyz2',
			element: <XyzChart2Page />,
			exact: true,
			subMenu: null,
		},
	},
	NR: {
		nrdashboard: {
			id: 'nrdashboard',
			text: '대시보드',
			type: 'superadmin',
			icon: 'BarChart',
			path: '/nrdashboard',
			element: <NrDashboardPage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		nrdeviceSettings: {
			id: 'nrdeviceSettings',
			text: '기기 목록',
			type: 'user',
			icon: 'AppSettingsAlt',
			path: '/nrdeviceSettings',
			element: <NrDeviceList />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		nrcollected2: {
			id: 'nrcollected2',
			text: '실시간 수집 데이터',
			type: 'user',
			icon: 'Preview',
			path: '/nrcollected2',
			element: <NrCollectedDataList2 />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		nrcollected3: {
			id: 'nrcollected3',
			text: '특정시간 데이터',
			type: 'user',
			icon: 'Preview',
			path: '/nrcollected3',
			element: <NrCollectedDataList3 />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		nrcollectedData: {
			id: 'nrcollectedData',
			text: '통계 수집 데이터',
			type: 'user',
			icon: 'Preview',
			path: '/nrcollectedData',
			element: <NrCollectedDataList />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		nrsystemSetting: {
			id: 'nrsystemSetting',
			text: '시스템 설정',
			type: 'user',
			icon: 'Settings',
			path: '/nrsystemSetting',
			element: <NrSystemSetting />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
	},
}

export default contents;
