import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import USERS from '../../common/data/userDummyData';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import { useMst } from '../../models';
import { useTranslation } from 'react-i18next';
import { removeItem } from '../../lib/localstorage';
import AuthService from '../../services/UserService';
import { observer } from 'mobx-react';
import Avatar from '../../components/Avatar';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} onError={`this.src='${USERS.SAM.srcSet}'`} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = observer(() => {
	const navigate = useNavigate();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { user } = useMst();
	const { t } = useTranslation(['translation', 'menu']); 

/* 	useEffect(() => {
		if (!user.id) {
			window.location.replace('/auth-pages/login');
		}
	}, [user]); */

	return (
		<Dropdown>
			<DropdownToggle hasIcon={false}>
				<div className='user belt-header'>
					<Avatar
						srcSet={user.me.profile}
						src={user.me.profile}
						userName={`${user?.name || ''}`}
						size={30}
						color={'primary'}
						textSizeRatio={10}
						maxInitials={2}
						fgColor={'dark'}
					/>
					<div className='user-info ms-3'>
						<div className='user-name title'>{`${ user?.name || ''}`}</div>
						<div className='user-sub-title'>{''}</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon='AccountBox'
						onClick={() => navigate(`/profile`)}>
						{t('Profile')}
					</Button>
				</DropdownItem>
				{/* <DropdownItem>
					<Button icon={darkModeStatus ? 'LightMode' : 'DarkMode'} onClick={() => setDarkModeStatus(!darkModeStatus)} aria-label='Toggle fullscreen'>
						{darkModeStatus ? t('화이트모드') : t('다크모드')}
					</Button>
				</DropdownItem>
				<DropdownItem isDivider /> */}
				<DropdownItem isDivider />
				<DropdownItem>
					<Button
						icon='Logout'
						className='belt-title'
						color={'light'}
						onClick={async () => {
							AuthService.logout().then(async () => {
								await Promise.all([
									removeItem('access-token'),
									removeItem('refresh-token'),
									removeItem('rootState'),
									removeItem('webToken'),
									removeItem('authUser'),
									user.logout(),
								]).then(() => {
									navigate(`/login`);
								});
							});
						}}>
						{t('로그아웃')}
					</Button>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
});

export default User;
