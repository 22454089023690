import { getItem, removeItem, setItem } from '../lib/localstorage';
import { onSnapshot, types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { User } from './User';

const RootModel = types.model({
	user: types.maybe(User),
});

let initialState = RootModel.create({
	user: {},
});

const data = getItem('rootState');
if (data) {
	const json = JSON.parse(data);
	if (RootModel.is(json)) {
		try {
			initialState = RootModel.create(json);
		} catch (e) {
			initialState = RootModel.create({
				user: {},
			});
		}
	}
}

export const rootStore = initialState;

onSnapshot(rootStore, (snapshot) => {
	if (snapshot.chat) {
		setItem('rootState', JSON.stringify(snapshot));
	} else {
		removeItem('rootState');
	}
});

const RootStoreContext = createContext(null);

export const { Provider } = RootStoreContext;
export const useMst = () => {
	const store = useContext(RootStoreContext);

	if (store === null) {
		throw new Error('Store cannot be null, please add a context provider');
	}
	return store;
};
