import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';

const ContentRoutes = () => {
	return (
		<Routes>
			{Object.entries(contents).map(([key, value]) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				Object.entries(value).map(([key, page]) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route key={key} path={page.path} {...page} />
				))
			))}
		</Routes>
	);
};

export default ContentRoutes;
